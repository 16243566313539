import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/layouts/Layout";
import { getSettings } from "../lib/Settings";

const AboutPage = ({ data }) => {
  const { page } = data;
  const settings = getSettings(data.settings);

  return (
    <Layout>
      <Helmet
        title={`${page.frontmatter.title} - ${settings.site.siteTitle}`}
        meta={
          page.frontmatter.description
            ? [{ name: "description", content: page.frontmatter.description }]
            : []
        }
      />
      <h1>{page.frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: data.page.html }} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    settings: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "settings" } } }
    ) {
      edges {
        node {
          ...settingsFragment
        }
      }
    }
    page: markdownRemark(frontmatter: { path: { eq: "/about" } }) {
      ...pageFragment
    }
  }
`;

export default AboutPage;
